import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import socialMediaMarketing from "../images/social-media-marketing.svg"
import customerExperience from "../images/customer-experience.png"
const Social = () => {
  return (
    <Layout>
      <main id="main" style={{ backgroundColor: "rgb(36, 41, 67)" }}>
        <h2 className="text-center text-4xl my-16 font-bold">Social Media</h2>
        <div className="grid grid-cols-3 grid-auto-rows px-16 md:px-32 lg:px-64">
          <div className="col-start-1 col-span-3 grid-row-1 md:col-span-2 row-start-1 my-16 pt-5">
            <h2 className="text-2xl font-bold pl-5">Marketing</h2>
            <p className="p-5 text-xl">
              Social media marketing allows you to connect with your audience
              personally and tell your story, inspiring others with your
              successes. Interacting with your customer base consistently over
              time and informing current and new fans of your company's journey
              builds brand awareness.
            </p>
          </div>

          <div className="col-start-1 col-span-3 md:col-span-1 row-start-2 md:row-start-1 my-16">
            <img
              src={socialMediaMarketing}
              style={{
                filter:
                  "invert(99%) sepia(68%) saturate(247%) hue-rotate(347deg) brightness(120%) contrast(100%)",
              }}
              className="opacity-75"
            />
          </div>

          <div className="col-start-1 col-span-3 md:col-start-2 row-start-3 md:row-start-2 my-16">
            <h2 className="text-2xl font-bold pl-5">Customer Experience</h2>
            <p className="p-5 text-xl">
              A recent Harvard Business Review Analytic Services Survey found
              that companies that thoroughly integrate social media and customer
              experience offer vastly superior customer experiences than
              competitors do, which translates into stronger growth and dominant
              positions in their markets.
            </p>
          </div>
          <div className="col-start-1 col-span-3 md:col-span-1 row-start-4 md:row-start-2 my-16">
            <img
              src={customerExperience}
              style={{
                filter:
                  "invert(99%) sepia(68%) saturate(247%) hue-rotate(347deg) brightness(120%) contrast(100%)",
              }}
              className="opacity-75"
            />
          </div>
          <div className="col-start-1 col-span-3 md:col-span-2 my-16">
            {/*<h2 className="text-2xl">Product Development</h2>
            <p>
              We use a multi-disciplinary approach encompassing the following
              methods:
            </p>
             <div className="w-full flex ">
              <ol className="list-disc list-inside">
                <li>Agile Development</li>
                <li>Project Management</li>
                <li>Wire Framing</li>
                <li>User Stories</li>
                <li>Scoping</li>
                <li>Visual Design</li>
              </ol>
            </div> */}
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default Social
